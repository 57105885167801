<template>
  <div class="warp-bg">
    <van-nav-bar
      :title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="goback">
      <p @click="onClickLeft">返回</p>
    </div>
    <div class="banner">
      <p v-if="mapInfo.video"><video :src="mapInfo.video" width="100%" height="100%" controls autoplay /></p>
      <p v-if="mapInfo.image"><img :src="mapInfo.image" alt=""></p>
      <p v-else><img src="@/assets/images/banner1.jpg"></p>
    </div>
    <div v-if="mapInfo.audio">
      <audio controls class="audio">
        <source :src="mapInfo.audio" type="audio/mpeg">
      </audio>
    </div>
    <div class="white-bg">
      <div class="show-title">
        <h1>{{ mapInfo.name }}</h1>
        <p v-if="mapInfo.score">
          <van-rate v-model="mapInfo.score" allow-half void-icon="star" readonly size="16" style="" color="#FFA717" void-color="#D8D8D8" />
          <span>{{ mapInfo.score.toFixed(1) }}</span>
        </p>
        <p><img src="/static/images/infoShow01.png">{{ mapInfo.address }}</p>
        <h5><img src="/static/images/infoShow03.png">"{{ mapInfo.recommend }}"</h5>
        <h3 v-if="mapInfo.distance" @click="handleMarker(mapInfo.id)"><img src="/static/images/map/map-list01.png">{{ (mapInfo.distance/1000).toFixed(1) }}km</h3>
      </div>
    </div>

    <div class="white-bg">
      <div class="show-info" v-html="mapInfo.info" />
    </div>
  </div>
</template>

<script>
import { getPoi } from '@/api/mapV2'
import { NavBar } from 'vant'
const defaultShow = {
  address: null,
  image: null,
  info: null,
  name: null,
  video: null
}

export default {
  name: 'MapShow',
  components: {
    [NavBar.name]: NavBar
  },
  data() {
    return {
      infoId: '',
      mapInfo: Object.assign({}, defaultShow)
    }
  },
  created() {
    this.infoId = this.$route.params && this.$route.params.id
    this.getPoiInfo()
    window.jumpLink = (val) => {
      const url = val.split('?')
      const params = url[1] ? url[1] : url[0]
      this.$router.push({ path: `/mapHome?${params}` })
    }
  },
  methods: {
    async getPoiInfo() {
      const data = {
        id: this.infoId,
        lng: this.$store.state.location.lng,
        lat: this.$store.state.location.lat
      }
      const res = await getPoi(data)
      this.mapInfo = res.payload.list[0]
      this.mapInfo.info = this.mapInfo.info.replace(/href="(.*?)"/g, "onclick=\"jumpLink('$1')\"")
    },
    handleMarker(id) {
      this.$router.push({ path: `/?type=poi&id=${id}` })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.goback{ width: 70px; height: 70px; position: fixed; bottom: 5%; right: 0; background: rgba(0, 0, 0, .5);}
.goback p{ font-size: 20px; line-height: 70px; text-align:center; color: #fff;}
.banner{
  img{ width: 100%; height: auto}
}
.white-bg{ .autoHeight(); margin-top:20px;  background :@white; padding: 0;}
.show-title{
  width :100%; position: relative; padding :20px;
  h1{ font-size :24px; line-height :50px; color :#333; padding-bottom :10px; border-bottom :1px solid #eee; margin-bottom: 10px; font-weight: bold; }
  p{ font-size: 16px; line-height: 30px; display: flex; color: #333; padding: 5px 0;
    img{ width: auto; height: 26px; margin-right: 10px; margin-top: 2px;}
  }
}
/deep/.show-info{
  width :100%;
  padding :10px 4% 20px;
  max-width:100% !important;
  h1,h2,h3,h4,h5,h6{ margin-top :5px; }
  p{ font-size :18px; line-height :34px; color :@black3;  margin-top :5px; }
  img{ max-width :100%; padding: 5px 0;}
}
</style>
